// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclosure-js": () => import("./../src/pages/disclosure.js" /* webpackChunkName: "component---src-pages-disclosure-js" */),
  "component---src-pages-economicgoals-js": () => import("./../src/pages/economicgoals.js" /* webpackChunkName: "component---src-pages-economicgoals-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-links-js": () => import("./../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-servicelevelagreement-js": () => import("./../src/pages/servicelevelagreement.js" /* webpackChunkName: "component---src-pages-servicelevelagreement-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tariff-js": () => import("./../src/pages/tariff.js" /* webpackChunkName: "component---src-pages-tariff-js" */)
}

